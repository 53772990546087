// global style
body {
    margin: 0;
    padding: 0;
    font-family: "Fira Sans", sans-serif;
    font-size: 16px;
    -webkit-text-size-adjust: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .App,
  html,
  #root {
    height: 100%;
  }
  .MuiPaper-elevation4 {
    position: fixed;
    z-index: 9999 !important;
  }
  header{
    background-color: #fff !important;
  }
  code {
    font-family: "Fira Sans", sans-serif;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    font-family: "Fira Sans", sans-serif;
  }
  .logo {
    img {
      max-width: 150px;
    }
  }
  .home {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  // main content style
  .main-content {
    margin: 100px 0px;
    height: 100%;
    width: 100%;
    float: left;
  }
  .main-inner {
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
  }
  .item-contain {
    text-align: left;
  }
  .logo-center {
    width: 100%;
    text-align: center;
    img {
      margin-left: 0px;
      width: 150px;
    }
  }
  // footer style
  .footer {
    z-index: 10900;
    position: relative;
    height: 90px;
    margin-top: auto;
    .MuiPaper-elevation1 {
      box-shadow: none;
    }
  }
  .footer-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    img {
      width: 80px;
    }
  }
  .footer-text {
    color: #00aeef;
    width: 100%;
    margin: 0 auto;
  }
  .footer-signout-btn {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }
  // header style
  .header-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 50px;
    margin-right: 15px;
    li {
      float: left;
      margin: 0 10px;
      a {
        color: #00aeef;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        transition: 0.4s;
      }
      &:hover a {
        color: #004990;
      }
    }
  }
  .header-menu-mobile {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      // float: left;
      margin: 0 10px;
      a {
        text-align: center;
        color: #00aeef;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        transition: 0.4s;
      }
      &:hover a {
        color: #004990;
      }
    }
  }
  .search-box {
    position: absolute;
    width: calc(100% - 300px);
    height: 36px;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 10px;
    transition: width 0.4s;
  }
  .username {
    width: 100%;
    float: left;
    margin: 15px 10px 10px;
  }
  // side bar style which is removed currntly
  .sidebar-header {
    justify-content: center !important;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
    border-right: 0px;
    img {
      width: 100px;
    }
  }
  // graph style
  .chart-main {
    width: 100%;
    overflow: auto;
  }
  
  .MuiTypography-root {
    margin-bottom: 10px !important;
  }
  .css-9mgopn-MuiDivider-root {
    margin: 15px 0 !important;
  }
  
  // welcome page
  .main-h1 {
    font-size: 90px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
  }
  
  .menu-mobile {
    display: flex;
    align-items: center;
  }
  
  // mobile responsive design
  @media only screen and (max-width: 767px) {
    .chart-main {
      max-width: 414px;
    }
    .header-menu-mobile {
      li {
        margin: 10px;
        a {
          font-weight: 400;
          margin: 10px 0;
        }
        &:last-child {
          text-align: center;
        }
      }
    }
    .search-box {
      width: calc(100% - 20px);
      left: 10px;
      top: 60px;
    }
    .header-menu {
      margin-left: 0px;
  
      margin-right: 0px;
    }
    .main-h1 {
      font-size: 50px;
    }
  }
  